import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusCompanyAddressGuide, CompanyAddressGuideEdges } from './company-address-guide.interface';

export default (): CompanyAddressGuideEdges[] => {
    const { allDirectusCompanyAddressGuide } = useStaticQuery<AllDirectusCompanyAddressGuide>(graphql`
    query {
      allDirectusCompanyAddressGuide {
        edges {
          node {
            directusId
            heading
            slug
            headers {
              name
            }
            rows {
              question
              slug
              registered_office
              service_address
              business_address
            }
            footnote
          }
        }
      }
    }
  `);

    return allDirectusCompanyAddressGuide.edges;
};
